import { lazyLoadView, lazyLoadNavRoutes } from './controller';

export const platform = {
  path: "/platform",
  component: lazyLoadView('platform'),
  children: [
    {
      path: "",
      name: "mt4",
      component: lazyLoadNavRoutes('platform/mt4')
    },
    {
      path: "platform",
      name: "platform",
      component: lazyLoadNavRoutes('platform/xTrader')
    },
    {
      path: "xMobile",
      name: "xMobile",
      component: lazyLoadNavRoutes('platform/xMobile')
    },
    {
      path: "xStore",
      name: "xStore",
      component: lazyLoadNavRoutes('platform/xStore')
    }
  ]
}